import { generateImageObject } from '../utils/generateImageObject';
import { generateVideoObject } from '../utils/generateVideoObject';
import { generateAnimationObject } from '../utils/generateAnimationObject';
import type { IMedia } from '~/utils/guards/isMediaType';
import { isMediaType } from '~/utils/guards/isMediaType';
import { useAnimation } from '~/composables/useAnimation';

export async function useMedia(
  media: IMedia | undefined,
  options?: {
    /** only for images */
    keepAspectRatio?: boolean;
    quality?: number;
  }
) {
  if (isMediaType(media, 'image')) {
    return generateImageObject(media, options);
  }

  if (isMediaType(media, 'video')) {
    return generateVideoObject(media.fields);
  }

  if (isMediaType(media, 'animation')) {
    const content = await useAnimation(media);
    if (content && content !== '') {
      return generateAnimationObject(media, content);
    }
  }

  if (isMediaType(media, 'customHtml')) {
    return generateIframeObject(media.fields);
  }
}
